import { MerchantSettingsValidationFieldNames } from '@components/forms/EditMerchantAdminForm.utils';
import { EcoButton, EcoInput, EcoLoader, EcoTab, EcoTabContent, EcoTabs, EcoText, FormPicker } from '@components/shared';
import { EcoPickerItem } from '@components/shared/EcoPicker';
import { FormCheckbox } from '@components/shared/FormCheckbox';
import EcoScrollView from '@components/views/EcoScrollView';
import { CASHBACK_PRICING_MODES, MerchantAdmin } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getMerchantAdmin, updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { CreateValidationSchema } from '@utils/validation';
import { Formik } from 'formik';
import { View } from 'react-native';

const pricingModeItems: EcoPickerItem[] = [
  { label: 'Percentage AOV', value: CASHBACK_PRICING_MODES[0] },
  { label: 'Flat Rate', value: CASHBACK_PRICING_MODES[1] }
];

export default function MerchantCashbackSettingScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchantAdmin, refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const { session } = useGlobal();
  const { handleError, showSuccess } = useOverlay();
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;

  const { mutate, isLoading: isSubmitting } = useMutation<
    MerchantAdmin,
    Error,
    { dbMerchantAdmin: MerchantAdmin; _merchantAdmin: MerchantAdmin }
  >(
    ({ _merchantAdmin }) => {
      return updateMerchantAdmin(_merchantAdmin.shopName, _merchantAdmin);
    },
    {
      onSuccess: async (newMerchantAdmin, { dbMerchantAdmin }) => {
        showSuccess('Updated merchant admin!');
        await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
          merchantAdmin: newMerchantAdmin,
          dbMerchantAdmin
        });
      },
      onError: (error) => {
        handleError(error.message);
      }
    }
  );

  const onSubmit = async (_merchantAdmin: MerchantAdmin) => {
    if (!merchantAdmin) return;

    mutate({ dbMerchantAdmin: merchantAdmin, _merchantAdmin });
  };

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantCashbackSetting"
      >
        <EcoTabContent title="Edit Cashback Settings">
          {merchantAdmin ? (
            <Formik
              initialValues={{ ...new MerchantAdmin(), ...merchantAdmin }}
              onSubmit={onSubmit}
              validationSchema={CreateValidationSchema(MerchantSettingsValidationFieldNames)}
            >
              {({ handleBlur, handleSubmit, handleChange, setFieldValue, errors, touched, values, isValid }) => (
                <View className="flex flex-col">
                  <View className="mb-3">
                    <FormCheckbox
                      label="Enable cashback"
                      value={values.cashbackEnabled as boolean}
                      onValueChange={(value: boolean) => setFieldValue('cashbackEnabled', value)}
                    />
                  </View>
                  <View className="mb-3">
                    <FormPicker
                      label="Pricing Mode"
                      items={pricingModeItems}
                      value={values.cashbackPricingMode as string}
                      onValueChange={handleChange('cashbackPricingMode')}
                    />
                  </View>
                  <View className="mb-3">
                    <EcoInput
                      label="Percentage"
                      value={values.cashbackPercentage as any}
                      placeholder="0.05"
                      onChangeText={handleChange('cashbackPercentage')}
                      field="cashbackPercentage"
                      onBlur={handleBlur('cashbackPercentage')}
                      errors={errors}
                      touched={touched}
                      helperText="Example: Enter 0.05 for 5% cashback on order value"
                    />
                  </View>
                  <View className="mb-6">
                    <EcoInput
                      label="Flat Rate Amount"
                      value={values.cashbackFlatRateAmount as any}
                      placeholder="5"
                      onChangeText={handleChange('cashbackFlatRateAmount')}
                      field="cashbackFlatRateAmount"
                      onBlur={handleBlur('cashbackFlatRateAmount')}
                      errors={errors}
                      touched={touched}
                      helperText="Example: Enter 5 for $5.00 cashback"
                    />
                  </View>
                  <View className="mb-1">
                    <EcoText fontSize="base" fontWeight="medium">
                      Discount code compatibility
                    </EcoText>
                  </View>
                  <View className="mb-5">
                    <EcoText fontSize="2xs" color="subdued">
                      Select the following checkboxes to allow the discount code created to be combined with other types of discounts
                    </EcoText>
                  </View>
                  <View className="mb-3">
                    <FormCheckbox
                      label="Order Discounts"
                      value={values.cashbackOrderDiscountEnabled as boolean}
                      onValueChange={(value: boolean) => setFieldValue('cashbackOrderDiscountEnabled', value)}
                    />
                  </View>
                  <View className="mb-3">
                    <FormCheckbox
                      label="Product Discounts"
                      value={values.cashbackProductDiscountEnabled as boolean}
                      onValueChange={(value: boolean) => setFieldValue('cashbackProductDiscountEnabled', value)}
                    />
                  </View>
                  <View className="mb-3">
                    <FormCheckbox
                      label="Shipping Discounts"
                      value={values.cashbackShippingDiscountEnabled as boolean}
                      onValueChange={(value: boolean) => setFieldValue('cashbackShippingDiscountEnabled', value)}
                    />
                  </View>
                  <EcoButton className="w-max mt-4" isDisabled={!isValid || isSubmitting} isLoading={isSubmitting} onPress={handleSubmit}>
                    Save
                  </EcoButton>
                </View>
              )}
            </Formik>
          ) : (
            <EcoLoader size="large" />
          )}
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
