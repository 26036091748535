import { EcoAccordion, EcoAlert, EcoButton, EcoCard, EcoInput, EcoRadioButtons, EcoText, EcoWidget, FormPicker } from '@components/shared';
import { ConfigContainer } from '@components/views/ConfigContainer';
import { LOCALES, Merchant } from '@ecocart/entities';
import { translations } from '@ecocart/universal-utils';
import { useOverlay } from '@hooks/useOverlay';
import { BackLink } from '@navigation/BackLink';
import { useNavigation } from '@react-navigation/native';
import { MerchantContext } from '@screens/MerchantWidgetsScreen';
import { updateMerchant } from '@utils/api/merchant';
import { Gap } from '@utils/layout';
import { colors } from '@utils/tailwind';
import { CreateValidationSchema } from '@utils/validation';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { CheckMarkColor, CheckboxPosition, getInitialValues } from './CheckoutWidgetDesigner.utils';
import {
  MOCK_CALCULATION,
  MOCK_CART,
  alignmentItems,
  alignmentItemsNoneOrCenter,
  convertCountryCodeToEnglishName,
  fieldPaths,
  getProps,
  getSelectedAlignmentValue,
  getTranslationPath,
  getZIndexProps,
  handleAlignmentChange,
  handleTypeCaseChange,
  typeCaseItems
} from './utils';

export function SimpleCheckoutWidgetDesigner({ widgetKey }: { widgetKey: string }): JSX.Element {
  const { showSuccess, handleError } = useOverlay();
  const { merchant, refetch } = useContext(MerchantContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { widgetMap } = merchant || {};
  const initialValues = widgetMap?.[widgetKey] || getInitialValues(merchant);
  const isCheckMarkBlack = get(initialValues, fieldPaths.checkMarkColor) === colors.black;
  const [checkMarkColor, setCheckMarkColor] = useState<CheckMarkColor>(isCheckMarkBlack ? 'black' : 'white');
  const isValidWidgetKey = widgetMap && widgetKey in widgetMap;
  const navigation = useNavigation();
  const [checkboxPosition, setCheckboxPosition] = useState<CheckboxPosition>('left');
  const isABTest = widgetMap?.[widgetKey]?.template.includes('ab_test');

  if (merchant && !isValidWidgetKey) {
    navigateToWidgetList();
  }

  function navigateToWidgetList() {
    if (!merchant) return;
    return navigation.navigate('MerchantWidgetTemplates', { shopName: merchant?.shopName, template: 'checkout-widget' });
  }

  const onSubmit = async () => {
    if (!merchant) return;

    setIsSubmitting(true);

    const updatedMerchant: Partial<Merchant> = {
      widgetMap: {
        ...merchant.widgetMap,
        [widgetKey]: values
      }
    };

    try {
      await updateMerchant(merchant.shopName, updatedMerchant, merchant);
      showSuccess('Successfully updated merchant');
      setIsSubmitting(false);
      refetch && refetch();
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: true,
    validationSchema: CreateValidationSchema(['elements']),
    enableReinitialize: true
  });
  const { values, errors, handleChange, setFieldValue, isValid, handleSubmit } = formik;

  useEffect(() => {
    const isCheckMarkWhite = checkMarkColor === 'white';
    setFieldValue(fieldPaths.checkMarkColor, isCheckMarkWhite ? colors.white : colors.black);
  }, [checkMarkColor, setFieldValue, handleChange]);

  return (
    <>
      <View className="pb-4">
        <BackLink onPress={navigateToWidgetList} label="Back To Widgets" />
      </View>
      <View style={Gap()}>
        <EcoCard>
          {isABTest && <EcoAlert variant="warning" title="This is an AB Test widget" closeable={false} className="mb-3" />}
          <EcoText fontSize="xl" fontWeight="semibold" className="mb-2">
            Preview
          </EcoText>
          <View>
            {values && merchant && <EcoWidget widget={values} merchant={merchant} cart={MOCK_CART} calculation={MOCK_CALCULATION} />}
          </View>
        </EcoCard>
        <EcoCard>
          <ConfigContainer>
            {/* Configuration component */}
            <form onSubmit={formik.handleSubmit}>
              <View style={Gap(8)}>
                <View style={Gap(6)}>
                  <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                    <View style={Gap()}>
                      <EcoText fontSize="xl" fontWeight="medium">
                        Widget Name
                      </EcoText>
                      <EcoInput label="Name" {...getProps(formik, fieldPaths.name)} />
                    </View>
                  </View>

                  <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                    <EcoText fontSize="xl" fontWeight="medium">
                      Container
                    </EcoText>
                    <View style={Gap(4)}>
                      <View>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Style
                        </EcoText>
                        <View className="flex-row flex-wrap" style={Gap()}>
                          <EcoInput
                            className="flex-1"
                            label="Background Color"
                            {...getProps(formik, fieldPaths.containerBackgroundColor)}
                            placeholder="#FFFFFF"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Width"
                            {...getProps(formik, fieldPaths.containerBorderWidth)}
                            placeholder="1px"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Color"
                            {...getProps(formik, fieldPaths.containerBorderColor)}
                            placeholder="#0F8354"
                          />
                          <EcoInput
                            className="flex-1"
                            label="Border Radius"
                            {...getProps(formik, fieldPaths.containerBorderRadius)}
                            placeholder="4px"
                          />
                        </View>
                      </View>
                      <View style={Gap(2)}>
                        <EcoText fontSize="lg" fontWeight="medium">
                          Behavior
                        </EcoText>
                        <View style={Gap(4)}>
                          <View>
                            <EcoText fontSize="base" fontWeight="medium">
                              Mobile
                            </EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.containerMargin)}
                                placeholder="0px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.containerPadding)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.wrapperWidth)}
                                placeholder="100px, 50%, etc"
                              />
                              <View>
                                <FormPicker
                                  inputClassName="flex-1"
                                  label="Default Alignment"
                                  items={alignmentItems}
                                  value={getSelectedAlignmentValue(values, 'wrapper', 'mobile')}
                                  onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile')}
                                  hasError={get(errors, fieldPaths.wrapperDisplay) || get(errors, fieldPaths.wrapperJustifyContent)}
                                />
                              </View>
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getZIndexProps(formik, fieldPaths.wrapperZIndex)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                          </View>
                          <View>
                            <EcoText fontSize="base" fontWeight="medium">
                              Desktop
                            </EcoText>
                            <View className="flex-row flex-wrap" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Margins"
                                {...getProps(formik, fieldPaths.containerMarginMD)}
                                placeholder="0px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Padding"
                                {...getProps(formik, fieldPaths.containerPaddingMD)}
                                placeholder="12px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Width"
                                {...getProps(formik, fieldPaths.wrapperWidthMD)}
                                placeholder="100px, 50%, etc"
                              />
                              <View>
                                <FormPicker
                                  inputClassName="flex-1"
                                  label="Default Alignment"
                                  items={alignmentItems}
                                  value={getSelectedAlignmentValue(values, 'wrapper', 'desktop')}
                                  onValueChange={(val) => handleAlignmentChange(formik, val, 'desktop', 'wrapper')}
                                  hasError={get(errors, fieldPaths.wrapperDisplayMD) || get(errors, fieldPaths.wrapperJustifyContentMD)}
                                />
                              </View>
                              <EcoInput
                                className="flex-1"
                                label="Z-Index"
                                {...getZIndexProps(formik, fieldPaths.wrapperZIndexMD)}
                                placeholder="10, 20, 100, etc"
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {!isABTest && (
                    <>
                      <View style={Gap(2)} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="xl" fontWeight="medium">
                          Content
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Alignment</EcoText>
                            <FormPicker
                              className="w-1/5"
                              label="Content Alignment"
                              items={alignmentItemsNoneOrCenter}
                              value={getSelectedAlignmentValue(values, 'container', 'mobile')}
                              onValueChange={(val) => handleAlignmentChange(formik, val, 'mobile', 'container')}
                            />
                          </View>
                          <View>
                            <View>
                              <EcoText fontWeight="medium">Checkbox</EcoText>
                              <View style={Gap()}>
                                <View className="flex-row" style={Gap()}>
                                  <EcoInput
                                    className="flex-1"
                                    label="Checkbox Fill"
                                    {...getProps(formik, fieldPaths.checkMarkBackgroundColor)}
                                    placeholder={colors.primary[400]}
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Checkbox Border Color"
                                    {...getProps(formik, fieldPaths.checkMarkBorderColor)}
                                    placeholder={colors.gray[800]}
                                  />
                                  <EcoInput
                                    className="flex-1"
                                    label="Checkbox Border Radius"
                                    {...getProps(formik, fieldPaths.checkMarkBorderRadius)}
                                    placeholder="2px"
                                  />
                                </View>
                                <View style={Gap()}>
                                  <View style={Gap(4)}>
                                    <EcoText fontSize="sm" className="mt-1">
                                      Checkmark Color
                                    </EcoText>
                                    <EcoRadioButtons
                                      value={checkMarkColor}
                                      items={[
                                        { label: 'White', value: 'white' },
                                        { label: 'Black', value: 'black' }
                                      ]}
                                      onValueChange={(val: CheckMarkColor) => setCheckMarkColor(val)}
                                    />
                                  </View>
                                  <View style={Gap()}>
                                    <EcoText fontSize="sm" className="mt-1">
                                      Checkbox Position
                                    </EcoText>
                                    <EcoRadioButtons
                                      value={checkboxPosition}
                                      items={[
                                        { label: 'Left', value: 'left' },
                                        { label: 'Right', value: 'right' }
                                      ]}
                                      onValueChange={(val: CheckboxPosition) => setCheckboxPosition(val)}
                                    />
                                  </View>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={Gap(2)} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="xl" fontWeight="medium">
                          Header
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Content</EcoText>
                            <EcoInput
                              className="flex-1 mb-3 md:mr-3 md:mb-0"
                              label="Header - English"
                              {...getProps(formik, getTranslationPath('header', 'en'))}
                              placeholder={translations.en.template_reduceMyOrderCO2}
                            />
                          </View>
                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`header-${locale}`}
                                className="flex-1"
                                label={`Header - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('header', locale))}
                                placeholder={translations[locale].template_reduceMyOrderCO2}
                              />
                            ))}
                          </EcoAccordion>
                        </View>

                        <View>
                          <EcoText fontWeight="medium">Style</EcoText>
                          <View style={Gap()}>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Font Family"
                                {...getProps(formik, fieldPaths.headerFontFamily)}
                                placeholder="system-ui"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Size"
                                {...getProps(formik, fieldPaths.headerFontSize)}
                                placeholder="14px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Line Height"
                                {...getProps(formik, fieldPaths.headerLineHeight)}
                                placeholder="16px"
                              />
                            </View>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Letter Spacing"
                                {...getProps(formik, fieldPaths.headerLetterSpacing)}
                                placeholder="normal"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.headerFontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Color"
                                {...getProps(formik, fieldPaths.headerColor)}
                                placeholder="#333333"
                              />
                              <FormPicker
                                label="Type Case"
                                items={typeCaseItems}
                                value={get(values, fieldPaths.headerTextTransform, '') as string}
                                onValueChange={(val) => handleTypeCaseChange(formik, val, 'header')}
                              />
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={Gap()} className="border-gray-200 border rounded-sm p-4">
                        <EcoText fontSize="lg" fontWeight="medium">
                          Learn More Modal
                        </EcoText>
                        <View style={Gap()}>
                          <View>
                            <EcoText fontWeight="medium">Content</EcoText>
                            <EcoInput
                              className="flex-1 mb-3 md:mr-3 md:mb-0"
                              label="Modal text - English"
                              {...getProps(formik, getTranslationPath('subHeader', 'en'))}
                              placeholder={translations.en.sustainable_shipping}
                            />
                          </View>
                          <EcoAccordion header="Show Translations">
                            {LOCALES.map((locale) => (
                              <EcoInput
                                key={`body-${locale}`}
                                className="flex-1 mb-3 md:mr-3 md:mb-0"
                                label={`Modal text - ${convertCountryCodeToEnglishName(locale)}`}
                                {...getProps(formik, getTranslationPath('subHeader', locale))}
                                placeholder={translations[locale].sustainable_shipping}
                              />
                            ))}
                          </EcoAccordion>
                        </View>
                        <View>
                          <EcoText fontWeight="medium">Style</EcoText>
                          <View style={Gap()}>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Font Family"
                                {...getProps(formik, fieldPaths.subHeaderFontFamily)}
                                placeholder="system-ui"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Size"
                                {...getProps(formik, fieldPaths.subHeaderFontSize)}
                                placeholder="14px"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Line Height"
                                {...getProps(formik, fieldPaths.subHeaderLineHeight)}
                                placeholder="16px"
                              />
                            </View>
                            <View className="flex-row" style={Gap()}>
                              <EcoInput
                                className="flex-1"
                                label="Letter Spacing"
                                {...getProps(formik, fieldPaths.subHeaderLetterSpacing)}
                                placeholder="normal"
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Weight"
                                {...getProps(formik, fieldPaths.subHeaderFontWeight)}
                                placeholder="300, 500, bold..."
                              />
                              <EcoInput
                                className="flex-1"
                                label="Font Color"
                                {...getProps(formik, fieldPaths.subHeaderColor)}
                                placeholder="#333333"
                              />
                              <FormPicker
                                label="Type Case"
                                items={typeCaseItems}
                                value={get(values, fieldPaths.subHeaderTextTransform, '') as string}
                                onValueChange={(val) => handleTypeCaseChange(formik, val, 'subheader')}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </>
                  )}
                </View>

                <EcoButton isDisabled={isSubmitting || !isValid} onPress={handleSubmit}>
                  Save
                </EcoButton>
              </View>
            </form>

            {/* End of Configuration component */}
          </ConfigContainer>
        </EcoCard>
      </View>
    </>
  );
}
